import React from 'react';
import './index.css';

function Description() {
  return (
    <div className="description">
      <img className='h5' src="/description/qrcode.png" alt="" />

      <div className="center">
        <a href="https://apps.apple.com/cn/app/%E9%BA%BB%E5%B0%86%E4%BC%A0%E5%A5%87/id6443441105" target="_blank">
          <img className='ios' src="/description/ios.png" alt="" />
        </a>
        <a href="https://h5-1256136475.file.myqcloud.com/mjcq/files/%E9%BA%BB%E5%B0%86%E4%BC%A0%E5%A5%87_GF.apk" target="_blank">
          <img className='android' src="/description/android.png" alt="" />
        </a>
      </div>
      <a href="https://h5-1256136475.file.myqcloud.com/mjcq/files/LEGENDOFLIQI_PC_Setup.exe" target="_blank">
        <img className='pc' src="/description/pc.png" alt="" />
      </a>
      
    </div>
  );
}

export default Description;
